<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-tabs @change="tabChange">
                    <a-tab-pane :tab="l('General')" key="1">
                        <a-row>
                            <!-- 图片质量 -->
                            <a-col :span="24">
                                <p class="text">{{l('PictureSettings')}}</p>
                            </a-col>
                            <a-col :span="24" style="display: flex;">
                                <!-- PictureQuality 图片质量 -->
                                <div style="margin-left: 15px;line-height: 35px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('PictureQuality') + '：'}}
                                    </span>
                                </div>
                                <a-select style="width: 300px;text-align: left;margin-right: 10px;"
                                    dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                                    :placeholder="l('please_select_Picture_Quality')" default-value="1"
                                    v-model="PictureQuality">
                                    <a-select-option v-for="item in PictureQualityList" :key="item.value"
                                        :title="item.name">
                                        {{ item.name +' '+item.resolutionratio }}
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="24" style="margin-top: 15px;">
                                <a-button :type="'primary'" @click="save()" v-if="isGranted('other_save')">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-col>

                            <!-- 私钥 -->
                            <a-col :span="24" style="margin-top: 20px;" v-if="TenantId == 10">
                                <a-input v-model="Privatekey" class="input"
                                    style="color: black;width: 300px; margin: 10px 10px 0 0; "
                                    :placeholder="l('PrivateKey')"></a-input>
                                <a-button :type="'primary'" @click="GeneratePrivatekey()">
                                    <a-icon />
                                    <span>{{ l('GenerateAPrivateKey') }}</span>
                                </a-button>
                            </a-col>
                            <a-col :span="24" style="margin-top: 15px;" v-if="TenantId == 10">
                                <a-button :type="'primary'" @click="savePrivatekey()">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-col>


                            <!-- 域信息配置 -->
                            <a-col :span="24" style="margin-top: 20px;">
                                <p class="text">{{l('LdapConfiguration')}}</p>
                            </a-col>
                            <a-col :span="24" style="display: flex;">
                                <div style="margin-left: 15px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Whether_it_is_enabled') + '：'}}
                                    </span>
                                </div>
                                <a-checkbox v-model="LdapIsActivate">
                                    {{l('enable')}}
                                </a-checkbox>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: 5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Domain_server_address') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="LdapDomainName" class="input"
                                    style="color: black;width: 300px; margin: 10px 10px 0 0; "
                                    :placeholder="l('Domain_server_address')"></a-input>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: -5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Domain_DN_address') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="LdapContainer" class="input"
                                    style="color: black;width: 300px; margin: 10px 10px 0 0; "
                                    :placeholder="l('Domain_DN_address')"></a-input>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: -5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Domain_account') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="LdapUserName" class="input"
                                    style="color: black;width: 300px; margin: 10px 10px 0 0; "
                                    :placeholder="l('Domain_account')"></a-input>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: -5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Domain_password') + '：'}}
                                    </span>
                                </div>
                                <a-input-password v-model="LdapPassword" class="input"
                                    style="color: black;width: 300px; margin: 10px 10px 0 0; "
                                    :placeholder="l('Domain_password')"></a-input-password>
                            </a-col>

                            <a-col :span="24" style="margin-top: 15px;">
                                <a-button :type="'primary'" @click="saveLdapSetting()" v-if="isGranted('other_save')">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>

                                <a-button :type="'primary'" @click="testLdapSetting()">
                                    <a-icon type="link" />
                                    <span>{{ l('AppTest') }}</span>
                                </a-button>
                                <span
                                    style="margin-left: 10px;">{{l('Save_the_LDAP_configuration_before_testing')}}</span>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane :tab="l('短信配置')" key="2">

                        <a-row>
                            <!-- 短信配置 -->
                            <a-col :span="24" style="margin-top: 20px;">
                                <p class="text">{{l('Whether_it_is_enabled')}}</p>
                            </a-col>
                            <a-col :span="24" style="display: flex;">
                                <div style="margin-left: 15px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('enable') + '：'}}
                                    </span>
                                </div>
                                <a-checkbox v-model="textmessageIsActivate">
                                    {{l('enable')}}
                                </a-checkbox>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: 5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('短信平台地址') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="textmessageSMS_Addr" class="input"
                                    style="color: black;width: 80%; margin: 10px 10px 0 0; "
                                    :placeholder="l('短信平台地址')"></a-input>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: -5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('短信平台账号') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="textmessageSMS_Account" class="input"
                                    style="color: black;width: 80%; margin: 10px 10px 0 0; "
                                    :placeholder="l('短信平台账号')"></a-input>
                            </a-col>
                            <a-col :span="24" style="display: flex;margin-top: -5px;">
                                <div style="margin-left: 15px;line-height: 50px;"
                                    :style="{'width': language == 'zh-Hans' ? '100px' : '120px'}">
                                    <span>
                                        {{l('Key值') + '：'}}
                                    </span>
                                </div>
                                <a-input v-model="textmessageSMS_Key" class="input"
                                    style="color: black;width: 80%; margin: 10px 10px 0 0; "
                                    :placeholder="l('Key值')"></a-input>
                            </a-col>

                            <a-col :span="24" style="margin-top: 15px;">
                                <a-button :type="'primary'" @click="savetextmessageSetting()">
                                    <a-icon type="save" />
                                    <span>{{ l('Save') }}</span>
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                </a-tabs>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        IssueServiceProxy,
        EHSServiceProxy,
        SystemConfigServiceProxy,
        TenantSettingsServiceProxy,
        HostSettingsServiceProxy
    } from '../../../shared/service-proxies';
    import {
        ModalHelper
    } from '@/shared/helpers';
    import { abpService } from '@/shared/abp';

    let _this;
    export default {
        name: "system-config",
        mixins: [AppComponentBase],
        components: {

        },
        created() {
            _this = this;
            this.form = this.$form.createForm(this, { name: "register" });
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
            this.EHSServiceProxy = new EHSServiceProxy(this.$apiUrl, this.$api);
            this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
            this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
            this._hostSettingsServiceProxy = new HostSettingsServiceProxy(
                this.$apiUrl,
                this.$api
            );
        },
        mounted() {
            this.language = localStorage.getItem('qyb-language');
            this.TenantId = abpService.abp.multiTenancy.getTenantIdCookie();
            this.getPictureQuality();
            this.getPrivatekey();
            this.getLdapSetting();
            this.gettextmessageSetting();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
                isLoading: false,
                photo: false,
                remark: false,
                date: {},
                remarkDate: {},
                IsRequiredIssueResp: false,
                Privatekey: undefined,
                PictureQuality: undefined,
                PictureQualityList: [
                    {
                        value: '1,0.3',
                        name: '小尺寸',
                        resolutionratio: '1280*720p',
                        scale: 0.3,
                        size: 200
                    },
                    {
                        value: '2,0.5',
                        name: '清晰',
                        resolutionratio: '1280*720p',
                        scale: 0.5,
                        size: 500
                    },
                    {
                        value: '3,0.7',
                        name: '高清',
                        resolutionratio: '1920*1080p',
                        scale: 0.7,
                        size: 1000
                    }
                ],
                tokenExpiration: undefined,

                LdapIsActivate: false,
                LdapDomainName: undefined,
                LdapContainer: undefined,
                LdapUserName: undefined,
                LdapPassword: undefined,
                language: 'zh-Hans',
                TenantId: 0,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 2 },
                        sm: { span: 2 }
                    },
                    wrapperCol: {
                        xs: { span: 10 },
                        sm: { span: 10 }
                    }
                },
                textmessageIsActivate: false,
                textmessageSMS_Addr: undefined,
                textmessageSMS_Account: undefined,
                textmessageSMS_Key: undefined,
            }
        },
        methods: {
            getPictureQuality() {
                this.isLoading = true;
                this.SystemConfigServiceProxy.getPictureQuality()
                    .finally(() => {
                        this.isLoading = false;
                    }).then(res => {
                        this.PictureQuality = res;
                    })
            },
            save() {
                //图片质量
                this.isLoading = true;
                this.SystemConfigServiceProxy.pictureQuality(
                    { PictureQuality: this.PictureQuality }
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                })

                this.$notification.success({
                    description: undefined,
                    message: this.l('SavedSuccessfully'),
                });
            },

            getPrivatekey() {
                this.isLoading = true;
                this.EHSServiceProxy.getEHSPrivatekey(

                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.Privatekey = res;
                })
            },

            GeneratePrivatekey() {
                console.log(1);
                this.isLoading = true;
                this.EHSServiceProxy.generatePrivatekey()
                    .finally(() => {
                        this.isLoading = false;
                    }).then(res => {
                        this.Privatekey = res;
                    })
            },
            savePrivatekey() {
                this.isLoading = true;
                this.EHSServiceProxy.eHSPrivatekey(
                    {
                        EHSPrivatekey: this.Privatekey
                    }
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                })

                this.$notification.success({
                    description: undefined,
                    message: this.l('SavedSuccessfully'),
                });
            },



            getLdapSetting() {
                this.isLoading = true;
                this.SystemConfigServiceProxy.getLdapSetting()
                    .finally(() => {
                        this.isLoading = false;
                    }).then(res => {
                        this.LdapIsActivate = res.ldapIsActivate;
                        this.LdapDomainName = res.ldapDomainName;
                        this.LdapContainer = res.ldapContainer;
                        this.LdapUserName = res.ldapUserName;
                        this.LdapPassword = res.ldapPassword;
                    })
            },
            saveLdapSetting() {
                //ldap
                this.isLoading = true;
                this.SystemConfigServiceProxy.ldapSetting(
                    {
                        LdapIsActivate: this.LdapIsActivate,
                        LdapDomainName: this.LdapDomainName,
                        LdapContainer: this.LdapContainer,
                        LdapUserName: this.LdapUserName,
                        LdapPassword: this.LdapPassword
                    }
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                })

                this.$notification.success({
                    description: undefined,
                    message: this.l('SavedSuccessfully'),
                });
            },

            testLdapSetting() {
                this.isLoading = true;
                this.SystemConfigServiceProxy.testLdapSetting()
                    .finally(() => {
                        this.isLoading = false;
                    }).then(res => {
                        if (!res) {
                            abp.message.info('连接失败，请检查配置信息');
                            return;
                        }
                        else {
                            abp.message.success('连接成功');
                            return;
                        }
                    })
            },

            gettextmessageSetting() {
                this._tenantSettingServiceProxy
                    .getAllSettings()
                    .finally(() => { })
                    .then(res => {
                        if (res.textMessage.smS_IsActivate == null) {
                            res.textMessage.smS_IsActivate = false;
                        }
                        this.textmessageIsActivate = res.textMessage.smS_IsActivate;
                        this.textmessageSMS_Addr = res.textMessage.smS_Addr;
                        this.textmessageSMS_Account = res.textMessage.smS_Account;
                        this.textmessageSMS_Key = res.textMessage.smS_Key;
                    });
            },

            tabChange(data) {
                
            },

            savetextmessageSetting() {
                this.spinning = true;
                var obj = {textMessage:{
                    smS_IsActivate : this.textmessageIsActivate,
                    smS_Addr : this.textmessageSMS_Addr,
                    smS_Account  : this.textmessageSMS_Account,
                    smS_Key : this.textmessageSMS_Key
                }};
                this._tenantSettingServiceProxy
                    .updateAllSettings(obj)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(result => {
                        this.notify.success(this.l("SavedSuccessfully"));
                    });
            },
        }
    }
</script>

<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .text {
        font-size: 18px;
        font-weight: bold;
    }
</style>